<template>
  <v-container fluid>
    <a-card title="线路信息">
      <a-button slot="extra" type="danger" :loading="saving" :disabled="saving" size="large" @click="save"> 提交保存
      </a-button>
      <a-form-model ref="formRef" :model="postlineinfo" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="线路名称" prop="name">
              <a-input v-model="postlineinfo.name" placeholder="线路名称" size="large" :disabled="saving">
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="省份" prop="province">
              <a-select v-model="postlineinfo.province" :options="projects" placeholder="省份" size="large"
                :not-found-content="null" show-search :filter-option="filterOption" :disabled="saving">
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="组开局" prop="postofficeid">
              <a-select v-model="postlineinfo.postofficeid" :options="postoffices" placeholder="组开局" size="large"
                :not-found-content="null" show-search :filter-option="filterOption" :disabled="saving">
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="邮路级别" prop="postroutelevel">
              <a-select v-model="postlineinfo.postroutelevel" :options="postroutelevels" placeholder="邮路级别" size="large"
                :not-found-content="null" show-search :filter-option="filterOption" :disabled="saving">
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="始发" prop="departure">
              <a-input v-model="postlineinfo.departure" placeholder="始发" size="large" :disabled="saving">
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="到达" prop="destination">
              <a-input v-model="postlineinfo.destination" placeholder="到达" size="large" :disabled="saving">
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="寄达省" prop="destprovince">
              <a-select v-model="postlineinfo.destprovince" :options="provinces" placeholder="寄达省" size="large"
                :not-found-content="null" show-search :filter-option="filterOption" :disabled="saving">
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="性质" prop="transport_kind">
              <a-select v-model="postlineinfo.transport_kind" :options="transport_kinds" placeholder="性质" size="large"
                :not-found-content="null" show-search :filter-option="filterOption" :disabled="saving">
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :lg="12" :md="24">
            <a-form-model-item label="去程-返程" prop="transport_list">
              <a-row :gutter="16" v-for="(item, index) in postlineinfo.transport_list" :key="index">
                <a-col :span="10">
                  <a-input v-model="item.transport_out" placeholder="去程" size="large" :disabled="saving">
                  </a-input>
                </a-col>
                <a-col :span="10">
                  <a-input v-model="item.transport_in" placeholder="返程" size="large" :disabled="saving">
                  </a-input>
                </a-col>
                <a-col :span="4">
                  <a-button v-if="index === postlineinfo.transport_list.length - 1" type="primary" shape="circle"
                    class="success" icon="plus" @click="addtransport" />
                  <a-button v-if="index >= 0 && index < postlineinfo.transport_list.length - 1" type="primary"
                    shape="circle" class="error" icon="minus" @click="removetransport(index)" />
                </a-col>
              </a-row>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="所属公司" prop="corp">
              <a-select v-model="postlineinfo.corp" :options="corps" placeholder="所属公司" size="large"
                :not-found-content="null" show-search :filter-option="filterOption" :disabled="saving">
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :xl="6" :lg="8" :md="12">
            <a-form-model-item label="备注" prop="remark">
              <a-textarea v-model="postlineinfo.remark" placeholder="备注" :auto-size="{ minRows: 5, maxRows: 5 }" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
  </v-container>
</template>
<script>
import _ from "underscore";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
  name: "PostLineCreate",
  data() {
    return {
      locale,
      rules: {
        name: [{ required: true, message: '线路名称不能为空' }],
        province: [{ required: true, message: '省份不能为空' }],
        postofficeid: [{ required: true, message: '组开局不能为空' }],
        postroutelevel: [{ required: true, message: '邮路级别不能为空' }],
        departure: [{ required: true, message: '始发不能为空' }],
        destination: [{ required: true, message: '到达不能为空' }],
        destprovince: [{ required: true, message: '寄达省不能为空' }],
        transport_kind: [{ required: true, message: '性质不能为空' }],
        corp: [{ required: true, message: '所属公司不能为空' }],
        transport_list: [{
          required: true,
          validator: (rule, value, callback) => {
            console.log(rule, value);
            if (value.length === 0) return callback(new Error('去程/返程不能为空'));
            for (let i = 0; i < value.length; i++) {
              let { transport_out, transport_in } = value[i];
              if (!transport_out) return callback(new Error('去程不能为空'));
              if (!transport_in) return callback(new Error('返程不能为空'));
            }
            callback();
          },
          trigger: 'change'
        }],
      },
      vrules: {
        required: (value) => !!value || "不能为空"
      },
      projects: [],
      provinces: [],
      corps: [],
      postoffices: [],
      postroutelevels: [],
      transport_kinds: [],
      vehicle_types: [],
      carriers: [],
      postlineinfo: {
        _id: "", name: "", province: undefined, postofficeid: undefined,
        postroutelevel: undefined, departure: "", destination: "", destprovince: undefined,
        transport_kind: undefined, transport_list: [], corp: undefined, remark: "",
      },
      saving: false,
    };
  },
  activated() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.$toast.loading({ message: "" });
      this.$api.doAPI("/postline/nanning/info", { _id: this.$route.query.id }).then((res) => {
        this.$toast.clear();
        // console.log(res);
        if (res.code === 0) {
          this.postoffices = res.data.postoffices;
          this.carriers = res.data.carriers;
          this.projects = res.data.projects;
          this.provinces = res.data.provinces;
          this.corps = res.data.corps;
          this.postroutelevels = res.data.postroutelevels;
          this.transport_kinds = res.data.transport_kinds;
          this.postlineinfo = res.data.postlineinfo;
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.$toast.clear();
        console.error(err);
      });
    },
    save() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          this._save();
        } else {
          return false;
        }
      });
    },
    _save() {
      this.saving = true;
      this.$api.doAPI("/postline/nanning/save", { postlineinfo: this.postlineinfo }).then((res) => {
        this.$toast.clear();
        this.saving = false;
        if (res.code === 0) {
          this.$toast.success("提交成功");
          this.$router.replace({ path: "/postline/nanning/list" });
        } else {
          this.$alert(res.msg);
        }
      }).catch((err) => {
        this.saving = false;
        this.$toast.clear();
        console.error(err);
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    //添加去程-返程信息
    addtransport() {
      this.postlineinfo.transport_list.push({ transport_out: "", transport_in: "", });
    },
    //删除去程返程信息
    removetransport(index) {
      this.postlineinfo.transport_list.splice(index, 1);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .row {
  margin-bottom: 0 !important;
}

/deep/ .col-12 {
  padding-bottom: 0 !important;
}

/deep/ .v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
